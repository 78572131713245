
.payment-method-modal__container {
  display: flex;
  flex-direction: column;
  min-height: 400px;

  h4:first-child {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.payment-method-modal__button-container {
  margin-top: auto;
  transition: all 0.3s ease-in-out 0.1s;

  button {
    width: 100%;
    margin-top: 20px;
    font-size: 1.5rem;
    padding: 10px;
    border: none;
    background-color: #0470de;
    color: #fff;
    border-radius: 5px;
    line-height: 1.5;
  }

  button:hover {
    background-color: #0053ba;
  }
}

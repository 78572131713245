table {
  width: 100%;
  margin-bottom: 20px;
}

.settings-section {
  margin-bottom: 10px;
}

.controls-section {
  margin-bottom: 5px;
}

.test-live-config__result {
  text-align: center;
}

.test-live-config__result__text {
  text-align: center;
  // color: #5e5e5e;
  background-color: #f5f5f5;
  border-radius: 100px;
  padding: 2px 22px;
  display: inline-block;
  /* font-weight: bold; */
  // font-size: 1.1rem;
  margin-top: 20px;
}

.test-live-config__pending-status {
  text-align: center;
  max-height: 50px;
  transition: all 0.5s ease;
  /* transition-delay: 0.5s; */
  overflow: hidden;
}

.test-live-config__pending-status__text {
  color: #727272;
  background-color: #f5f5f5;
  border-radius: 100px;
  padding: 1px 18px;
  display: inline-block;
  /* font-weight: bold; */
  font-size: 1.1rem;
  margin-bottom: 12px;
  max-height: 50px;
  transition: all 0.5s ease;
  /* transition-delay: 0.5s; */
  overflow: hidden;
}

.no-height {
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  transition: all 0.5s ease;
  /* transition-delay: 0.5s; */
}

.test-live-config__controls {
  display: inline-block;
  width: 350px;
  vertical-align: top;
  padding: 20px;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px RGB(0 0 0 / 10%), 0 2px 4px -2px RGB(0 0 0 / 10%);
}

.test-live-config__input {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.test-live-config__input--no-button {
  border-radius: 4px;
}

.test-live-config__input:disabled {
  background-color: #fcfcfc;
  color: #888;
}

.test-live-config__button {
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-weight: bold;
  background-color: #efefef;
  display: inline-block;
  vertical-align: bottom;
}

.test-live-config__button--input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 38px;
  border: 1px solid #d2d6de;
  border-left: none;
  padding: 8px 12px;
}

.test-live-config__label--input {
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 38px;
  border: 1px solid #d2d6de;
  border-right: none;
  padding: 8px 8px;
  margin: 0px;
  vertical-align: bottom;
}

.test-live-config__button.test-live-config__start-button {
  background-color: #21b194;
  padding: 0px 20px;
  border: none;
  color: white;
  white-space: nowrap;
}

.test-live-config__button.test-live-config__start-button:hover {
  background-color: #37c3a7;
}

.test-live-config__button.test-live-config__start-button:disabled {
  background-color: #b2ddd4;
  color: #f7f7f7;
}

.test-live-config__button.test-live-config__finish-button {
  background-color: #dd4b39;
  color: white;
}

.test-live-config__button.test-live-config__finish-button:hover {
  background-color: #e66a5a;
}

.test-live-config__button.test-live-config__finish-button:disabled {
  background-color: #e6b3b0;
  color: #f7f7f7;
}

.test-live-config__button:hover {
  background-color: #e0e0e0;
}

.test-live-config__button:disabled {
  cursor: auto;
  background-color: #efefef;
  color: #999;
}

// .test-live-config__step__hear {
//   display: flex;
//   align-items: end;
// }

// .test-live-config__step__hear::before {
//   content: "";
//   display: inline-block;
//   width: 17px;
// }

// .test-live-config__step__hear.last::before {
//   /* content: "";
//   display: inline-block; */
//   width: 0;
//   height: 0;
//   border-top: 10px solid transparent;
//   border-right: 17px solid #f3f5f9;
//   margin-bottom: 5px;
// }

// .test-live-config__step__hear__bubble {
//   display: inline-block;
//   /* max-width: calc(100% - 17px); */
//   max-width: 430px;
//   padding: 8px 10px;
//   background-color: #f3f5f9;
//   border-radius: 10px;
//   border-bottom-left-radius: 0px;
//   margin-bottom: 5px;
// }

// .test-live-config__step__hear__heading {
//   display: inline-block;
//   background-color: #d7edff;
//   padding: 0px 14px;
//   border-radius: 22px;
//   font-size: 1.1rem;
//   /* height: 14px; */
//   line-height: 15px;
//   color: #085798;
//   margin: 2px 0px;
// }

// .test-live-config__step__hear__icon {
//   display: inline-block;
//   border-radius: 50px;
//   background-color: #d7edff;
//   padding: 7px;
//   /* margin-bottom: -10px; */
//   /* transform: translateY(13px); */
//   margin-right: 5px;
//   margin-top: 0px;
//   vertical-align: bottom;
// }

// .test-live-config__step__respond__bubble {
//   display: inline-block;
//   max-width: calc(100% - 17px);
//   padding: 8px 10px;
//   background-color: #eeedfc;
//   border-radius: 10px;
//   border-bottom-right-radius: 0px;
//   margin-bottom: 5px;
// }

// .test-live-config__step__respond::after {
//   content: "";
//   display: inline-block;
//   width: 0;
//   height: 0;
//   border-top: 10px solid transparent;
//   border-left: 17px solid #eeedfc;
//   margin-bottom: 5px;
//   vertical-align: bottom;
// }

.test-live-config__chat-bubble__heading {
  display: inline-block;
  background-color: #dddcff;
  padding: 0px 12px;
  border-radius: 22px;
  font-size: 1.1rem;
  /* height: 14px; */
  line-height: 15px;
  color: #525095;
  margin: 2px 5px 2px 0px;
}

.test-live-config__chat-bubble__content {
  display: inline-block;
}

.test-live-config__chat-bubble__digit {
  margin-top: -2px;
  margin-bottom: -2px;
  text-align: center;
  padding: 2px 6px;
  /* margin: 2px 1px; */
  background-color: #f7f9fc;
  border-radius: 4px;
  font-weight: bold;
  /* font-size: 1.5rem; */
  /* color: white; */
  /* border: none; */
  display: inline-block;
  border: 1px solid #b1b3b6;
}

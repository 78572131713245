
.subscriptions__container {
  margin-bottom: 15px;
}

.subscriptions__card-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.subscriptions__card {
  flex-basis: 100%;

  @media (min-width: 1024px) {
    flex-basis: calc(50% - 10px);
  }
}

.balance-card__container {
  min-height: 170px;
  height: 100%;
}

.balance-card__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.balance-card__header-button {
  cursor: pointer;
  margin-left: 8px;
  padding: 2px 4px;
  font-size: 1rem;
  height: unset;
}

.balance-card__admin-controls {
  display: flex;
  gap: 8px;
  margin-top: auto;
}

.balance-card__cta {
  border: none;
  background-color: #3c8dbc;
  color: #fff;
  padding: 10px 12px;
  border-radius: 6px;
  font-size: 1.5rem;
}

.payment-methods__add-button {
  border: none;
  background-color: #3c8dbc;
  color: #fff;
  // padding: 10px 12px;
  // border-radius: 6px;
  // font-size: 1.5rem;
}

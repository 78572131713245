
.subscription-form__datepicker__range {
  padding: 7px 30px;
  border-radius: 5px;
  border: 1px solid #ddd;
  text-align: center;
}

.subscription-form__datepicker__text {
  margin-left: 5px;
  width: 75px;
  display: inline-block;
}


.c-dropdown-menu {
  display: inline-block;
  vertical-align: middle;
}

.c-dropdown-menu__menu {
  position: absolute;
  white-space: nowrap;
  //top: 100%;
  // left: 0;
  z-index: 1000;
  padding: 4px 0px;
  margin: 2px 0 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.c-dropdown-menu__item {
  cursor: pointer;
  padding: 10px 12px;

  &:hover {
    background-color: #fafaff;
  }
}


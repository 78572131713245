.form-control.invalid {
    border: 1px solid #f30000;
}


#invalid.weekday-holder {
    padding: 10px 0px;
    border: 1px solid #f30000;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
}

#invalid.rc-time-picker-input {
    border: 1px solid #f30000;

}
.modal-category{
    width: 225px;
    height: 28px;
    border-radius: 4px;
}
.category-font{
    width: 60px;
    height: 19px;
    top: 174px;
    left: 322px; 
    font-family: Cisco Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    background: #484848;
}

.modal-operand{
    width: 403px;
    height: 32px;
    top: 168px;
    left: 452px;
    border-radius: 5px;
    border: 1px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid #676767;
    padding: 5px;
}

.value-layout{
  width: 310px;
  height: 32px;
  top: 168px;
  left: 668px;
  border-radius: 5px;
  border: 1px solid #676767;
  padding: 10px;
}

.btn-add{
  display: flex;
  float: right;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background:linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #405071;
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}   

.btn-delete{
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background:linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #405071;
}   



.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.column {
    float: left;
  }

  .iconButtonStyle {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 10px; /* Add padding for better visual appearance */
    color: #405071;
  }

  .iconSelectStyle {
    color:#6553AD;
    
  }

  .btn-search{
    width: 80px;
    height: 30px;
    background-color: #2F6E8C; 
    float: right;
    border: 1px #2F6E8C;
    border-radius: 4px;
    color: #2F6E8C;
    margin-bottom: 20px;
    margin-right: 20px;

  }



  .search{
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #FFFFFF;
    font-family: Roboto;
  }
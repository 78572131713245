
// .chat-bubble__container {
//   border: 0px;
// }

.chat-bubble__container--left {
  display: flex;
  align-items: end;
}

.chat-bubble__container--left::before {
  content: "";
  display: inline-block;
  width: 17px;
}

.chat-bubble__container--left.chat-bubble__container--last::before {
  /* content: "";
  display: inline-block; */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 17px solid #f3f5f9;
  margin-bottom: 5px;
}

.chat-bubble {
  max-width: 400px;
  display: inline-block;
}

.chat-bubble--left {
  padding: 8px 10px;
  background-color: #f3f5f9;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  margin-bottom: 5px;
}

// .chat-bubble--left__heading {
//   display: inline-block;
//   background-color: #d7edff;
//   padding: 0px 14px;
//   border-radius: 22px;
//   font-size: 1.1rem;
//   /* height: 14px; */
//   line-height: 15px;
//   color: #085798;
//   margin: 2px 0px;
// }

// .chat-bubble--left__icon {
//   display: inline-block;
//   border-radius: 50px;
//   background-color: #d7edff;
//   padding: 7px;
//   /* margin-bottom: -10px; */
//   /* transform: translateY(13px); */
//   margin-right: 5px;
//   margin-top: 0px;
//   vertical-align: bottom;
// }

.chat-bubble__container--right {
  text-align: right;
}

.chat-bubble__container--right::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 17px solid #eeedfc;
  margin-bottom: 5px;
  vertical-align: bottom;
}

.chat-bubble--right {
  padding: 8px 10px;
  background-color: #eeedfc;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  margin-bottom: 5px;
  text-align: left;
}



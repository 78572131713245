.test-step-appear {
  max-height: 0px;
  opacity: 0.1;
  overflow: hidden;
  transition: all 0.5s ease;
}

.test-step-appear-active {
  max-height: 300px;
  opacity: 1;
  overflow: hidden;
  transition: all 0.5s ease;
}

.test-step-exit {
  max-height: 300px;
  opacity: 1;
  overflow: hidden;
  transition: all 0.3s ease;
}

.test-step-exit-active {
  max-height: 0px;
  padding: 0px !important;
  opacity: 0.1;
  overflow: hidden;
  transition: all 0.3s ease;
}

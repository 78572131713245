.phone-keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  max-width: 220px;
  margin: 10px auto;
}

.phone-keypad__key {
  text-align: center;
  padding: 10px;
  margin: 5px;
  background-color: #efefef;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.5rem;
  border: none;
}

.phone-keypad__key:hover {
  background-color: #e0e0e0;
}

.phone-keypad__key:disabled {
  cursor: auto;
  background-color: #efefef;
  color: #999;
}
